import React, { useEffect, useState } from "react";

const Carousel = (props) => {
    const { data, aspectRatio, time = 3000, bg = "gray-100", captions = [] } = props;
    const [timeDelay, setTime] = useState(time);
    const [stop, setStop] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const carouselInfiniteScroll = () => {
        if (stop) return;
        if (currentIndex === data.length - 1) {
            return setCurrentIndex(0);
        }
        return setCurrentIndex(currentIndex + 1);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            carouselInfiniteScroll()
        }, timeDelay)
        return () => clearInterval(interval)
    }, [currentIndex, timeDelay, stop])

    return (
        <div className={`super-container bg-${bg} rounded-sm md:rounded-lg p-4 pb-0 flex flex-col justify-center`} style={{ aspectRatio }}>
            <div className='carousel-container flex flex-nowrap overflow-hidden m-auto '>
                {
                    data.map((item, index) => {
                        return (
                            <img
                                onClick={() => carouselInfiniteScroll()}
                                onMouseOver={e => setTime(time * 2)}
                                onMouseLeave={e => setTime(time)}
                                className='carousel-item object-contain aspect-video min-w-full flex justify-center items-center text-4xl transition-all'
                                style={{ transform: `translate(-${currentIndex * 100}%)` }}
                                key={index}
                                src={item}
                                alt={`carousel-item-${index}`}
                            />
                        );
                    })
                }
            </div >

            <div className="carousel-caption text-center py-2 min-h-10">
                {captions[currentIndex] && <p className="font-sans font-bold">{captions[currentIndex]}</p>}
            </div>

            <div className="carousel-bar flex justify-center p-2">
                {
                    data.map((item, index) => {
                        return (
                            <img
                                src={item}
                                onClick={() => setCurrentIndex(index)}
                                key={index}
                                className={`bar ${currentIndex === index ? 'opacity-100 border-black border-[3px]' : 'opacity-50'} w-6 h-6 object-contain rounded-md md:w-10 md:h-10 md:rounded-lg m-1 cursor-pointer transition-all`}
                                alt={`bar-item-${index}`}
                            />
                        );
                    })
                }
            </div>
        </div>
    )
}

export default Carousel