import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { IconContext } from "react-icons";
import { MdArrowOutward } from "react-icons/md";

const SpotifyAPI = require("spotify-current-track");

const spotify = new SpotifyAPI({
    clientId: process.env.REACT_APP_SPOTIFY_CLIENT_ID,
    clientSecret: process.env.REACT_APP_SPOTIFY_CLIENT_SECRET,
    refreshToken: process.env.REACT_APP_SPOTIFY_REFRESHTOKEN,
    _timeout: 1000 // milliseconds
});

const Footer = () => {
    const [isPlaying, setIsPlaying] = useState(false)
    const [currentTrack, setTrack] = useState("")
    useEffect(() => {
        spotify.currentTrack.then(track => {
            setIsPlaying(track.is_playing)
            setTrack(track)
        }).catch(e => setIsPlaying(false));
    }, [])
    return (
        <IconContext.Provider value={{ style: { verticalAlign: 'bottom', display: 'inline' } }}>
            <footer className="footer bg-black">
                <div className="content Stack ultrawide-container ">
                    <div className="VStack md:HStack h-full gap-8">
                        <div className="text-white uppercase font-bold font-panel gap-2 VStack">
                            <div className='text-4xl HStack justify-between'>
                                <span>Connect
                                <br />
                                with
                                <br />
                                me<MdArrowOutward className='connect-icon transition'/></span>
                            </div><br />
                            if you like my projects.<br />
                            or if we have same music taste.
                            {
                                isPlaying && (
                                    <div className="VStack text-sm mt-4 w-[300px] md:w-[350px] self-start spotify-player font-sans bg-primary h-[96px] p-2.5 opacity-100 hover:scale-[1.05] transition hover:opacity-100 content-between">
                                        <div className="h-[24px] text-xs text-white uppercase font-bold">Nikesh is currently listening to</div>
                                        <div className="HStack h-auto">
                                            <div className="music-img-wrapper h-full relative inline">
                                                <img className="w-50px" src={currentTrack.item.album.images[2].url} alt="Currently what Nikesh is listening to" />
                                                <img className="w-50px bottom-0 right-0 absolute h-full opacity-40" src="https://i.pinimg.com/originals/05/4a/a3/054aa3421c22e0c9e04ada3082066a8d.gif" />
                                            </div>
                                            <div className="VStack items-start text-white px-2 py-1 normal-case gap-0">
                                                <div className="artist-song h-1/2 font-bold w-[200px] md:w-[250px] truncate">{currentTrack.item.name}</div>
                                                <div className="artist-name h-1/2 font-normal md:w-[250px] truncate">{currentTrack.item.artists[0].name}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className="HStack md:VStack flex-wrap content-end gap-4">
                            <div className="text-white grid text-left md:text-right gap-0 uppercase transition">
                                {/* <Link to="/about" rel="noopener noreferrer">About Me</Link>
                    <Link to="/projects" rel="noopener noreferrer">Projects</Link> */}
                                <a href="mailto:nikeshk@umich.edu" target="_blank" rel="noopener noreferrer" className="hover:font-black">Email</a>
                                <a href="https://behance.com/nikeshh" target="_blank" rel="noopener noreferrer" className="hover:font-black">Behance</a>
                                <a href="https://github.com/nikeplusdash" target="_blank" rel="noopener noreferrer" className="hover:font-black">GitHub</a>
                                <a href="https://www.linkedin.com/in/nikesh-kumar/" target="_blank" rel="noopener noreferrer" className="hover:font-black">LinkedIn</a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </IconContext.Provider>

    );
};

export default Footer;