import React, { useState, useEffect } from 'react';
import './Navbar.css';
import logo from '../static/logo-dark.svg';

const Navbar = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    let x;
    // console.log((x = window.location.href.split("/"))[x.length - 1])
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleMenu = () => {
        setIsExpanded(!isExpanded);
        document.querySelector('.btn2').classList.toggle('open');
    };

    return (
        <nav className={`navbar-container sticky z-50 top-0 bg-background md:bg-transparent backdrop-blur-sm hover:bg-background transition`}>
            <div className={`'HStack navbar ultrawide-container font-semibold md:font-normal ${isExpanded ? 'expanded' : ''}`}>
                <div className='first-column-nav'>
                    <a href="/" className="navbar__logo">
                        <img src={logo} className='w-12' alt="Logo" />
                    </a>
                    {isMobile && (
                        <div className="navbar__button">
                            <div className="btn2" data-menu="2" onClick={toggleMenu}>
                                <div className="icon"></div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="navbar__links">
                    <a href="/about" className="navbar__link">About Me</a>
                    {/* <a href="/projects" className="navbar__link">Projects</a> */}
                    {/* <a href="/resume" className="navbar__link">Resume <span className="material-icons">open_in_new</span></a> */}
                    <a href="https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/preview/UX+Resume.pdf" target="_blank" className="navbar__link">Resume</a>
                    <a href="https://www.linkedin.com/in/nikesh-kumar/"target="_blank" className="navbar__link">LinkedIn</a>
                </div>
            </div>
            <div className='divider bg-black-white h-px' />
        </nav>
    );
};

export default Navbar;