import React from 'react';

import Transition from '../../components/Transition';
import TableOfContents from '../../components/ToC';
import Highlighter from '../../components/Highlighter';
import Heading from '../../components/Heading';
import EmbedIt from '../../components/EmbedIt';
import LinkButton from '../../components/LinkButton';
import Quote from '../../components/Quote';
import Carousel from '../../components/Carousel';
import SEO from '../../components/SEO';

const NIMProject = () => {
    const toc = [
        "Overview",
        "Goals",
        "Mockups",
        "Drafts",
        "Reflection"
    ]
    return (
        <main className='p-0'>
            {/* <SEO title="Developing Brand Identity for Newman Insight Marketing" description="Ma'at is a project conceptualized and designed for SI 616: Advanced Topics in Graphic Design and Communication where I had to create a brand identity inspired by a designer of our choice. I chose a designer whose artwork felt closer to me and with Stefanie Posavec as reference for the project, I created Ma'at. Ma'at was designed to be a challenge for merging Stephanie Posavec's design thinking with a completely new brand unrelated to any of her previous works." image="https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/preview/nim-Web-Preview.png" /> */}
            <div className='bg-white'>
                <TableOfContents contentsArray={toc} />
                <img src="https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/nim/NIM-Hero.png" alt="NIM" className="hero-image xl:w-7/12  m-auto" />
                <div className='content VStack gap-2.5 ultrawide-container'>
                    <div id="overview" className="project-title section text-nim-primary py-4">Developing Brand Identity for Newman Insight Marketing</div>
                    <div className="project-subtitle">
                        <div className="detail-title">About Newman Insight Marketing (NIM)</div>
                        Newman Insight Marketing is a new firm founded by Heather Newman, who has more than 15 years of experience in educational, nonprofit and government marketing and communications. Heather's social media campaigns have been written about in the Wall Street Journal, and her marketing outreach efforts have exceeded clients' goals.
                        <br /><br />
                        <div className="detail-title">Project Overview</div>
                        As Design Intern, I was tasked with brand development for Newman Insight Marketing. The project involved creating adaptable brand identities and practical assets, from custom logos and mockups. Additionally I took initiative to create a streamlined invoicing system that would be efficient and practical for real-world use.
                    </div>
                    <div className="details grid grid-cols-2 gap-4 md:HStack my-8">
                        <div className="detail-section VStack">
                            <div className="detail-title">Role</div>
                            <div className="detail-content">Digital and Graphic Design Intern</div>
                        </div>
                        <div className="detail-section VStack">
                            <div className="detail-title">Timeline</div>
                            <div className="detail-content">May '24 to Aug '24</div>
                        </div>
                        <div className="detail-section VStack">
                            <div className="detail-title">Tools</div>
                            <div className="detail-content">
                                <div>Figma</div>
                                <div>Google Sheets</div>
                                <div>Affinity Publisher/Adobe Indesign</div>
                                <div>Affinity Designer/Adobe Illustrator</div>
                                <div>Glyph</div>
                            </div>
                        </div>
                    </div>

                    {/* How might we/I develop a design concept that embodies the principles of nim, drawing on the distinctive style and philosophy of [Artist's Name], to create a cohesive and engaging brand experience? */}
                </div>
                <Heading id="goals" text={"Goals"} content={<>How Can I <br /><br />A. Create a cohesive brand image that aligns with Heather's desired perception.<br /><br />B. Develop versatile mockups that can be utilized without requiring a subscription or any prior design expertise.</>} mode={5} primary={"nim-primary"} background={"white"} />
                {/* <br /> */}
                <Heading id="mockups" text={"Mockups"} content={"Clear"} mode={3} primary={"nim-primary"} background={"white"} />
                <div className="content ultrawide-container flex flex-col gap-5 md:gap-8 bg-black text-white">
                    <div className="detail-title">Design Approach</div>
                    My approach was to establish a design narrative based on likes dislikes from the design brief. I created a mood board to understand the client's preferences and then developed several logo concepts. Through multiple iterations of feedback, I refined the logo to a final design that was then used to create mockups for business cards, letterheads, and invoices.
                    <br /><br />
                    The design should fit with NIM's non-profit, governmental and educational area of operation, and should be adaptable to different media such as web and paper.
                    <div>
                        Following the logo design, I designed logos for NIM's branches of operation, and created mockups for business cards, letterheads, and invoices. While working as a designer, I designed an invoice which could be edited by designer in Figma, but I wanted to make it more versatile for anyone with no knowledge to use it. So I said <Highlighter textColor="white" highlightColor="nim-primary" href="https://youtu.be/t2q7Z1HXRZE?si=TLdo9XmduOV4alln&t=136"> nah imma do my own thing 🥚</Highlighter> (to learn more and give the best of my abilities). Having a background in computer science, I wanted to create an invoicing mechanism which would adapt to the data in real world into the design and generate an invoice in the desired template. I then adapted the icons into a font "NIM Icons" in normal and heavy weight using Glyph.
                    </div>
                    With identity in place, the next steps were developing templates for cover letters, social media posts, and presentation reports.
                    <Carousel
                        aspectRatio={16 / 9}
                        time={4000}
                        data={
                            [
                                "https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/nim/Logo-Lockups.png",
                                "https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/nim/Color-Scheme.png",
                                "https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/nim/Fonts.png",
                                "https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/nim/Cover-Letter.png",
                                "https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/nim/Letterhead.png",
                                "https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/nim/Business-Cards.png",
                                "https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/nim/SM-posts.png",
                                "https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/nim/Social-Media.png",
                            ]
                        }
                        captions={
                            ["Final logo with different lockups", "Color Scheme for internal design and different collaboration", "Icon Design along with the font", "Cover Letters – Different versions for different occasions", "Letterhead – Color & B/W versions", "Business Cards", "Social Media Posts – Different versions for different platforms", "Social Media – Different versions for different platforms"]
                        }
                    />
                    <EmbedIt src="https://embed.figma.com/design/8pnE4uFnOIfm6v5CX70A3T/Presentation-Template?node-id=0-1&embed-host=share" title="Presentation Slides Template" aspectRatio={706 / 1200} />

                    <EmbedIt src="https://player.vimeo.com/video/1015912703?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" title="Invoice Template Design" aspectRatio={706 / 1200} />

                </div>
                <Heading id="drafts" text={"Drafts"} content={"Clear"} mode={3} primary={"nim-primary"} background={"white"} />
                <div className="content ultrawide-container flex flex-col gap-5 md:gap-8">
                    <div className="detail-title">Ideation</div>
                    For ideation, I generated more than 50 rough drafts based on the design brief provided by Heather. Recognizing her likes and dislikes and the ideology of the company, we iterated over multiple designs to make and break new logos until we ended up with the final logo as shown above.
                    <EmbedIt src="https://embed.figma.com/board/4nV2iOG8AXttWWDp8Ma6Ca/Logos-Draft-I?node-id=0-1&embed-host=share" allowFullScreen title="Logo Ideation" aspectRatio={450 / 800} />

                </div>
    
                {/* Reflection */}
                <Heading id="reflection" text={"Reflection"} mode={3} primary={"nim-primary"} background={"white"} />
                <div className="content ultrawide-container flex flex-col bg-white text-black gap-5 md:gap-8">
                    I would like to describe my experience by these three key questions I would ask myself after each project:<br />

                    <div className="reflection flex flex-col gap-4">
                        <div className="reflection-content">
                            <div className="project-subheading font-bold">What went well?</div>
                            <div className="project-content py-1">
                                Being the sole designer, I had the complete freedom to approach the ideation my way, however it also came with responsibilities of making decision, navigating ambiguity around a new project. The burden of decision was high as compared to a mockup companny, I was working with a new company whose logo would be forefront of every negotiation. This pushed me to bring elements of ux design such as competitive analysis and analyzing brands based on mass perception.
                                <br /><br />
                                Besides these, I had an excellent opportunity to learn about font designing and integrating my computer science background to build dynamic designs
                            </div>
                        </div>

                        <div className="reflection-content">
                            <div className="project-subheading font-bold"> What Could Be Improved?</div>
                            <div className="project-content py-1">
                                There were a lot of conflicting areas in design, where one would be fancy design serving the visual purpose and other would be a simple design serving the functional purpose dictated by the conservative markets. I would like to tackle this problem with research and understanding the market better.
                            </div>
                        </div>

                        <div className="reflection-content">
                            <div className="project-subheading font-bold"> What Possibilities Emerge?</div>
                            <div className="project-content py-1">
                                This project opened new directions in terms of design style. It gave me a chance to explore how design and development can be integrated to create a more dynamic design. As such, I would like to bring more pragmatic and functional scripting to visual design.

                                I also loved working in a startup environment, because it gives me lot of horizontal scale at work and I can work on different areas of design and development, which is a great learning experience.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Transition(NIMProject);