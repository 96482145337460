import { useState, useEffect } from 'react';
import React from 'react';
import Heading from '../components/Heading';
import ProjectCard from '../components/ProjectCard';
import Transition from '../components/Transition';

const MainPage = () => {
    return (
        <main>
            <i className='Generator-Tailwind-Classes text-quicken-secondary bg-primary bg-highlight bg-quicken-primary bg-nim-primary text-nim-secondary text-maat-primary bg-maat-primary text-maat-secondary text-highlight text-[#1db500] bg-[#1db500]'></i>
            <div className='content h-[calc(100vh-72px)] justify-center ultrawide-container VStack gap-5 md:gap-8'>
                <div className='VStack gap-2.5 font-panel text-black  text-xl md:text-2xl font-black w-full md:w-3/5'>
                    <div className='text-primary text-3xl md:text-5xl'>Nikesh(<em>Nik</em>) Kumar </div>
                    <div className='text-black'>I design future-proof products that address real-world needs, leveraging my expertise in computer science, UX, and physical prototyping to create impactful, tangible experiences.</div>
                </div>
                <div className='VStack'>
                    <div className="currently text-sm text-shade italic">Currently</div>
                    <div className="studying text-sm">pursuing MS-HCI from University of Michigan</div>
                </div>
                <div className='VStack'>
                </div>
            </div>
            {/* <Heading text={"Projects"} primary={"black"} background="white" mode={3} /> */}
            <div className="content VStack gap-16 md:gap-24 ultrawide-container">
                {/* <div className='h-1 w-full bg-primary'></div> */}
                <ProjectCard
                    thumbnail="https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/thumbnails/NIM.svg"
                    image="https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/preview/NIM.png"
                    title="Developing Brand Identity for Newman Insight Marketing"
                    subtitle="Graphic Design | Internship"
                    content="Designing adaptable brand identities and practical assets: from custom logos and mockups to streamlined invoicing systems, built for efficiency and real-world use"
                    link="/projects/nim"
                    primary="nim-primary"
                    secondary="nim-secondary"
                // prototypeLink={"https://google.com"} 
                />
                <ProjectCard
                    thumbnail="https://logos-world.net/wp-content/uploads/2023/12/Quicken-New-Logo.png"
                    image="https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/preview/Quicken.png"
                    title="Improving Finance management with Quicken Simplifi"
                    subtitle="UX Research | Client Project"
                    content="Making taxation and finance management easier for users by improving the Simplifi app's usability and user experience."
                    link="/projects/quicken"
                    primary="quicken-primary"
                    secondary="quicken-secondary"
                // prototypeLink={"https://google.com"} 
                />
                <ProjectCard
                    hideIcon={true}
                    thumbnail="https://via.placeholder.com/38"
                    image="https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/preview/EduVerse.png"
                    title='Understanding Physics with EduVerse'
                    subtitle="AR/VR Development | Project"
                    content="VR environment for students to learn physics concepts (Laws of Motion & Optics) experientially through playground"
                    link="/projects/eduverse"
                    primary="[#1db500]"
                />
                <ProjectCard
                    thumbnail="https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/thumbnails/Maat.svg"
                    image="https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/preview/Maat.png"
                    title="Design pitch for Ma'at inspired by Stephanie Posavec"
                    subtitle="UI Design | Design Thinking | Branding | Project"
                    content="Ma'at, inspired by the Egyptian goddess of truth, balance, and order, offers premium health supplements designed to promote well-being through holistic harmony."
                    link="/projects/maat"
                    primary="maat-primary"
                    secondary="maat-secondary"
                />
            </div>
            {/* <Heading text={"And More"} primary={"black"} background="white" mode={3}/>
            <div className="content ultrawide-container gap-5 md:gap-8">
                Grid here
            </div> */}
        </main>
    );
};


// export default Transition(MainPage);
export default MainPage;