import React from 'react';
import SEO from '../../components/SEO';

import Heading from '../../components/Heading';
import Highlighter from '../../components/Highlighter';
import Quote from '../../components/Quote';
import TableOfContents from '../../components/ToC';
import Carousel from '../../components/Carousel';
import Transition from '../../components/Transition';
import EmbedIt from '../../components/EmbedIt';

const EduVerseProject = () => {
    const toc = [
        "Overview",
        "Problem Statement",
        "Solution",
        "Process",
        "Ideation",
        "Development",
        "Reflection"
    ]
    return (
        <main className='p-0'>
            <div className='bg-black text-white'>
                <SEO title="Understanding physics with EduVerse – Nikesh Kumar" description="EduVerse is a virtual reality environment designed to help students learn physics concepts experientially. The project was conceived as part of the coursework for SI 659: Developing AR/VR Experiences at the University of Michigan School of Information. Traditional physics classrooms often face limitation of resources, exploration, and engagement. Building a well-equipped lab can be expensive for schools. So our overarching question is how can we use modern technology to make study more intuitive and accessible." image="https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/preview/Eduverse-Web-Preview.png" />
                <TableOfContents contentsArray={toc} />
                <img src="https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/eduverse/Eduverse-Hero.png" alt="EduVerse" className="hero-image m-auto" />
                <div className='content VStack gap-2.5 ultrawide-container'>
                    <div id="overview" className="project-title section text-neon-green py-4">Understanding physics with EduVerse</div>
                    <div className="project-subtitle">
                        <div className="detail-title">Project Overview</div>
                        EduVerse is a virtual reality environment designed to help students learn physics concepts experientially. The project was conceived as part of the coursework for <Highlighter textColor="black" highlightColor="neon-green" href="https://www.si.umich.edu/programs/courses/659">SI&nbsp;659:&nbsp;Developing&nbsp;AR/VR&nbsp;Experiences</Highlighter> at the University of Michigan School of Information.
                        Traditional physics classrooms often face limitations in terms of resources, exploration, and engagement. Building a well-equipped lab can be expensive for schools. So, our overarching question is: How can we use modern technology to make studying more intuitive and accessible? For this purpose, our goal is to: <b>Create an accessible, intuitive and engaging experience of teaching Physics material that can be demonstrated in the form of VR:</b> “Newton’s Law of Motion” & “Optics – Reflection”

                    </div>
                    <div className="details grid grid-cols-2 gap-4 md:HStack my-8">
                        <div className="detail-section VStack">
                            <div className="detail-title">Role</div>
                            <div className="detail-content">AR/VR Developer</div>
                        </div>
                        <div className="detail-section VStack">
                            <div className="detail-title">Timeline</div>
                            <div className="detail-content">Mar '24 to Apr '24 (4 weeks)</div>
                        </div>
                        <div className="detail-section VStack">
                            <div className="detail-title">Tools</div>
                            <div className="detail-content">
                                <div>FigJam</div>
                                <div>Unity</div>
                                <div>Blender</div>
                                <div>ChatGPT/Gemini</div>
                                <div>Meta Quest Pro</div>
                            </div>
                        </div>
                        <div className="detail-section VStack">
                            <div className="detail-title">Team</div>
                            <div className="detail-content VStack">
                                <div className="team-member">Reuben S Crasto</div>
                            </div>
                        </div>
                    </div>
                </div>

                <Heading id="problem-statement" text={"Problem Statement"} content={"How Might We make abstract physics concepts more tangible and relatable for students to connect physics principles to real world applications and phenomena?"} mode={5} primary={"neon-green"} background={"black"} highlight={"quicken-secondary"} />
                <div className="content ultrawide-container gap-5 md:gap-8">
                    <div className="project-subheading section font-bold w-2/5" id="solution">Solution</div>
                    <div className="project-content">
                        <br />
                        Our solution is a VR playground consisting of 2 scenes combines – a Newton’s Law of Motion scene and an Optics scene. The former, designed by me, is a classroom like environment with 3 different setups (2 interactable) and latter, designed by Reuben, is a playground with laser and mirror setup. The two scenes were then combined by me.
                    </div>
                    <EmbedIt src="https://player.vimeo.com/video/938886644?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" title="EduVerse Demo" aspectRatio={706 / 1200} />
                </div>
                <div className="content ultrawide-container bg-white text-black">
                    <div className='flex flex-col md:flex-row text-black gap-5 md:gap-8'>
                        <div id="process" className="project-subheading section font-bold w-2/5">How did i get here?</div>
                        <div className="project-content">
                            This project took far leap from the initial ideation. I segregated many ideas among the categories of AR and VR, subdivided into ideas that were novel and unique and ideas that were solving a problem.

                            <br /><br />
                        </div>
                    </div>
                    <img src="https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/eduverse/Brainstorm.png" alt="UX Research Process" className="project-image xl:w-8/12 m-auto p-8" />
                    <div className="project-content">
                        We shortlisted a bunch of ideas marked by yellow that felt challenging and cool to tackle so that we could build our skills and make a "cool" portfolio piece. I highly preferred Ouija Board VR – to show a paranormal experience in VR. My teammate Reuben was inclined toward Rage Room, and we both had a slight interest in idea of Piano Tiles in 3D.
                        <br /><br />
                    </div>
                </div>
                <Heading id="ideation" text={"Ideation"} mode={3} primary={"neon-green"} background={"black"} dividerColor={"white"} />
                <div className="content ultrawide-container flex flex-col bg-white text-black gap-5 md:gap-8">
                    <div className="project-content">
                        We assessed the technical proficiency needed for each project with the help of our instructor and ranked them based on our interest and the technical feasibility. However, while my friend was playing a game, there was an advertisement for learning maths through blocks. This was the eureka moment for me and I shared this with my teammate. We both agreed that this was a unique idea to build in VR, and we could make it more engaging and fun. Our motivation stemmed from our common childhood experience.
                        <Quote color="black">Everytime I went to Physics Lab, or any lab as of fact, I had a set of instruments, a given time and a goal to achieve. <br />That's it. <br />Physics was not physic-ing</Quote>

                        Based on our own experiences, we identified the best target audience to be middle school students, for whom physics is a new subject, and they are still learning the basics and find technology fascinating and engaging. Using that as a starting point, we drew and shortlisted on knowledge of lab experiments and real-world physics applications to identify the most impactful learning experiences.
                        <br /><br />
                    </div>
                </div>
                <Heading id="development" text={"Development"} mode={3} primary={"neon-green"} background={"black"} dividerColor={"white"} />
                <div className="content ultrawide-container flex flex-col bg-white text-black gap-5 md:gap-8">
                    <div className="project-content">
                        Our development followed 3 main stages:

                        {/* https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/eduverse/Eduverse-Development.png */}
                        <img src="https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/eduverse/Eduverse-Development.png" alt="Development Process" />

                        <br /><br />
                    </div>
                </div>
                <Heading id="reflection" text={"Reflection"} mode={3} primary={"neon-green"} background={"black"} dividerColor={"white"} />
                <div className="content ultrawide-container flex flex-col bg-white text-black gap-5 md:gap-8">
                    <Carousel
                        aspectRatio={16 / 10}
                        time={3000}
                        data={
                            [
                                "https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/eduverse/Setup.jpg",
                                "https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/eduverse/Setup_w_People.jpg",
                                "https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/eduverse/Expo-Poster.png"

                            ]
                        }
                    />

                    I would like to describe my experience by these three key questions I would ask myself after each project:<br />

                    <div className="reflection flex flex-col gap-4">
                        <div className="reflection-content">
                            <div className="project-subheading font-bold">What went well?</div>
                            <div className="project-content py-1">
                                While we imagined a lot more for this project, we had to limit our scope due to time constraints and our current game development knowledge. However, as we know the
                                <Highlighter textColor="black" highlightColor="neon-green" href="https://youtu.be/a5_8PFzEhB0?si=j-Dy327J1db7DAqr&t=109">the best way to learn 🥚</Highlighter> is to enthusiastically face our challenges (and use ChatGPT & instructors' assistance). Thus, we successfully created an MVP for our project and demonstrated our project's core features at the
                                <Highlighter textColor="black" highlightColor="neon-green" href="https://www.si.umich.edu/about-umsi/events/2024-umsi-student-project-exposition">UMSI&nbsp;2024:&nbsp;Exposition</Highlighter> showcase. We received positive feedback from our peers and instructors, and we received the following steps to take our project further. This was a great learning experience for me, and I am proud of what we accomplished in such a short amount of time. Lastly, I would like to thank my teammate, Reuben Crasto, who has been a great partner throughout this project. We both had a lot of fun working on this project and learned much from each other. I am grateful for his support and excited to see where this project goes in the future.
                            </div>
                        </div>

                        <div className="reflection-content">
                            <div className="project-subheading font-bold"> What Could Be Improved?</div>
                            <div className="project-content py-1">
                                There were many directions to take this project, such as enabling eye gaze to show forces on the body, creating a UI controller for turning gravity on and off, changing time speed, and so on. However, extensive exploration took a lot of work due to the current time frame. Besides these a crucial place, my teammate and I would like to take this project further by testing with an authentic audience and doing a study to analyze contextual problems, as this is highly based on our experience and contextual to Indian students & us more. We want to make it more universal.
                            </div>
                        </div>

                        <div className="reflection-content">
                            <div className="project-subheading font-bold"> What Possibilities Emerge?</div>
                            <div className="project-content py-1">
                                This project has opened up many possibilities for me in the field of education. I have always been passionate about education, and I have always wanted to make a difference in the field of education, which I did by participating in peer mentoring and teaching assistant roles. This project has shown me that I can use my technical skills to make a difference in education. I am excited to explore more projects in this field and take this project further. If you are interested in collaborating on this project or have any feedback, please contact me!
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
};

export default Transition(EduVerseProject);